function openModal(modalID) {
  $(modalID).modal();
}

function openModalStatic(modalID) {
  $(modalID).modal({
    backdrop: 'static',
    keyboard: false
  });
}

function closeModal(modalID) {
  $(modalID).modal('hide');
  $('body').removeClass('modal-open');
  $('.modal-backdrop').remove();
}

function TabSelect(tabID) {
  console.log('TabSelect : ' + tabID);
  $("#" + tabID + " a[href='#" + tabID + "']").tab('show');
}

function ClickOnId(id) {
  console.log('Click : ' + id);

  document.getElementById(id).click();
}

function destroy(tableID) {
  // let tableElfID = $('#' + tableID);
  // tableElfID.DataTable({
  //   language: {
  //     emptyTable: "กำลังโหลด..."
  //   }
  // }).clear().draw();
}

function ChangeDataTablesEmptyText(tableID, text) {
  $("#" + tableID + " .dataTables_empty").text(text);
}

function initDataTable(
  tableID,
  lengthChange = true,
  searching = false,
  paginf = true,
  info = true,
  responsive = true,
  text = "กำลังโหลด..."
) {
  let tableElfID = $('#' + tableID);


  let resTable

  if ($.fn.dataTable.isDataTable('#' + tableID)) {
    resTable = tableElfID.DataTable();
    resTable.clear()
  } else {
    resTable = tableElfID.DataTable({
      responsive: responsive,
      lengthChange: lengthChange,
      searching: searching,
      serverSide: false,
      processing: true,
      fixedHeader: true, // แก้ให้ fix header กลับมาใช้งานได้
      lengthMenu: [
        [50, 75, 100, -1],
        [50, 75, 100, "All"]
      ],
      order: [], // เอา default sort ออกไปเพื่อให้ sort ข้อมูลจาก db มาถูกต้อง
      pageLength: 50,
      paging: paginf,
      info: info,
      language: {
        "loadingRecords": "กำลังโหลด...",
        "emptyTable":     "ไม่พบข้อมูล",
      }
    });
  }
  return resTable;
}

function reloadDataTable(tableID) {
  var cpt = 0;
  var dir = 1;
  var table = $('#' + tableID).dataTable();

  table.clear().draw();
  setInterval(function () {

    var d = table.row(cpt).data();
    // d.name = (dir > 0 ? d.name + ' ASC' : d.name.slice(0, -4));
    table.row(cpt).data(d);
    table.row(cpt).invalidate();

    table.draw(false);
    cpt = cpt + dir;
    if (cpt > 55 || cpt < 1) {

      dir = dir * -1;
    }


  }, 100);

}

function LightGallery(galleryID, firstRowsNumber = false, rowNumber = 1, rowHeight = 80) {
  return new Promise((resolve, reject) => {
    resetSessionGallery(galleryID);
    let light = null;
    var $initScope = $('#' + galleryID);
    //console.log('initScope : ', $initScope);
    if ($initScope.length) {
      light = $initScope.justifiedGallery({
        border: -1,
        rowHeight: rowHeight,
        //maxRowsCount: 1,
        // maxRowHeight: true,
        // margins: 8,
        rowNumber: rowNumber,
        waitThumbnailsLoad: true,
        randomize: false

      }).on('jg.complete', function (r) {

        let lightGallery = $initScope.lightGallery({
          thumbnail: true,
          animateThumb: true,
          showThumbByDefault: true,
          speed: 800
        });

        //console.log('TmsgalleryTmp  :', TmsgalleryTmp);

        if (!firstRowsNumber) {
          //after set gallery
          resetSessionGallery(galleryID);
        }
        resolve(lightGallery);
      });
    };
    $initScope.on('onAfterOpen.lg', function (event) {
      $('body').addClass("overflow-hidden");
    });
    $initScope.on('onCloseAfter.lg', function (event) {
      $('body').removeClass("overflow-hidden");
    });
  });

}

function resetSessionGallery(sessionName) {
  var checksession = sessionStorage.getItem(sessionName);
  if (checksession) {
    sessionStorage.removeItem(sessionName);
  }
}

function AlertError(title, msg) {
  if (typeof bootbox != 'undefined') {
    bootbox.confirm({
      title: "<i class='fal fa-exclamation-circle text-danger mr-2'></i>พบข้อผิดพลาด: " + title,
      message: '<span>' + msg + '</span>',
      centerVertical: true,
      swapButtonOrder: true,
      buttons: {
        confirm: {
          label: 'ยืนยัน',
          className: 'btn-danger shadow-0',
        },
        cancel: {
          label: 'ปิด',
          className: 'btn-default',
        },
      },
      className: 'modal-alert',
      closeButton: false,
      callback: function (result) {
        if (result == true) {
          return true;
        }
      },
    });
  }
}

function PopUpImage(modalID, scriamge) {

  // Get the modal
  //var modal = document.getElementById(modalID);

  // Get the image and insert it inside the modal - use its "alt" text as a caption
  //var img = document.getElementById(ImageId);
  var modalImg = document.getElementById("imgonShow");
  $(modalID).modal();
  //modal.style.display = "block";
  modalImg.src = scriamge;

}

function ClosePopUpImage(modalID) {

  // Get the modal
  var modal = document.getElementById(modalID);

  modal.style.display = "none";

}
function TriggerFbMsg(){
  // console.log("Trigger")
// (function(d, s, id) {
//   var js, fjs = d.getElementsByTagName(s)[0];
//   if (d.getElementById(id)) return;
//   js = d.createElement(s); js.id = id;
//   js.src = 'https://connect.facebook.net/th_TH/sdk/xfbml.customerchat.js';
//   fjs.parentNode.insertBefore(js, fjs);
// }(document, 'script', 'facebook-jssdk'));
}
function secondsToHms(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + (h == 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m == 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay + sDisplay;
}

function secondsToHm(d) {
  d = Number(d);
  var h = Math.floor(d / 3600);
  var m = Math.floor(d % 3600 / 60);
  // var s = Math.floor(d % 3600 % 60);

  var hDisplay = h > 0 ? h + " ชั่วโมง" : "";
  var mDisplay = m > 0 ? " " + m +" นาที" : "";
  // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
  return hDisplay + mDisplay;
}
function DateTimeDiff(startDate, endDate) {
  // start = start.split(":");
  // end = end.split(":");
  // var startDate = new Date(0, 0, 0, start[0], start[1], 0);
  // var endDate = new Date(0, 0, 0, end[0], end[1], 0);
  var diff = endDate.getTime() - startDate.getTime();
  var hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  var minutes = Math.floor(diff / 1000 / 60);

  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0)
     hours = hours + 24;

  return (hours <= 9 ? "0" : "") + hours + ":" + (minutes <= 9 ? "0" : "") + minutes;
}
